import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center w="100%" bg="#C60000" h="50px">
          <CFImage w="75%" src={logo} alt="Domo Sushi Sushi Logo" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column w="100%" bg="#C60000" zIndex={98} h="55px">
          <CFImage
            mt="10px"
            ml="6%"
            w="100%"
            maxWidth="255px"
            src={logo}
            alt="Domo Sushi Sushi Logo"
            zIndex={98}
            boxShadow="1px 1px 3px rgba(0,0,0,.6)"
          />
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
